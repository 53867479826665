<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-10 18:12:57
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-18 13:44:12
-->
<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit" okText="提交" :maskClosable="false">
        <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
            <!-- <a-form-model-item label="标签类型" prop="typeId">
                <a-select v-model="rowData.typeId" placeholder="请选择">
                    <a-select-option :value="item.id" v-for="(item, index) in tagList" :key="index">{{item.title}}</a-select-option>
                </a-select>
            </a-form-model-item> -->
            <a-form-model-item label="标签名称" prop="name">
                <a-input v-model="rowData.name" placeholder="标签名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="描述" prop="(describeMsg">
                <a-input v-model="rowData.describeMsg" placeholder="描述"></a-input>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { editDealerCustomerTag, selectByIdDealerCustomerTag, addDealerCustomerTag } from '../api/DealerCustomerTagApi'
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                typeId: [
                    { required: true, message: '请选择标签类型', trigger: 'change' }
                ],
                name: [
                    { required: true, message: '请输入标签名称', trigger: 'blur' }
                ],
                describeMsg: [
                    { required: true, message: '请输入描述', trigger: 'blur' }
                ],
            },
            tagList: []
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData = {}
            
            if(handle === 'edit') {
                selectByIdDealerCustomerTag(row.id).then(res => {
                    this.rowData = res.body
                })
            }

            this.axios.get('/api/base/system/dictionary/selectByCode/客户标签类型').then(res => {
                this.tagList = res.body
            })
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                this.rowData.sourceType = 2
                const res = this.handle === 'add' ? await addDealerCustomerTag(this.rowData) : await editDealerCustomerTag(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        }
    },
    created() {

    },

}
</script>

<style lang="less" scoped>

</style>
